<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t('g.pending_review')}} 🛠</h2>

        <p class="mb-3">
          {{ $t('g.your_data_has_been_sent_to_the_administration_and_will_be_reviewed_as_soon_as_possible')}}.
        </p>

        <!-- <b-form
          inline
          class="justify-content-center row m-0 mb-2"
          @submit.prevent
        >
          <b-form-input
            id="notify-email"
            class="col-12 col-md-5 mb-1 mr-md-2"
            placeholder="john@example.com"
          />

        </b-form> -->

        <!-- img -->
        <b-img fluid :src="imgUrl" alt="Under maintenance page" />
        <b-button
          @click="logout"
          variant="primary"
          class="mb-1 btn-sm-block"
          type="submit"
        >
          log out
        </b-button>
      </div>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BFormInput, BButton, BForm, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    VuexyLogo,
  },
  data() {
    return {
      downImg: require("@/assets/images/auth/under.png"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/under-maintenance-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  created(){

  },
  methods: {
    logout() {
      localStorage.clear();
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: this.$t(`g.good_bye`),
          icon: "CheckCircleIcon",
          variant: "warning",
          text: this.$t(`g.you_have_successfully_logged_out`),
        },
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
